(function () {
    'use strict';

    angular
        .module('ipmApp')
        .controller('FRPReportPsychController', FRPReportPsychController);

    FRPReportPsychController.$inject = ['$scope', '$state', '$stateParams', 'frpReport', 'templates', 'FrpReportService', 'DataUtils', 'ipmConstants', '$uibModal', 'isView', 'DateUtils', '$filter', 'MdeReportService', '$window', '$rootScope', '$timeout'];

    function FRPReportPsychController($scope, $state, $stateParams, frpReport, templates, FrpReportService, DataUtils, constants, $uibModal, isView, DateUtils, $filter, MdeReportService, $window, $rootScope, $timeout) {
        var vm = this;
        vm.frpReportOld = null;
        vm.frpReport = frpReport ? frpReport : {};
        vm.templates = templates ? templates : [];
        vm.isLoadingData = false;
        vm.isDisabled = false;
        vm.isFinalize = false;
        vm.isChanged = isChanged;
        vm.disableFields = {
            subjective: false,
            psychologicalNarrative: false,
            psychTestingSelect: false,
            testingInitialScore: false,
            testingInitialScoreLeft: false,
            testingInitialScoreRight: false,
            testingColMidsession: false,
            testingColMidsessionRight: false,
            testingColMidsessionLeft: false,
            testingMidsession: false,
            testingMidsessionLeft: false,
            testingMidsessionRight: false,
            testingDischarge: false,
            testingDischargeLeft: false,
            testingDischargeRight: false,
            testingNotes: false
        };
        vm.cancelMessage = 'ipmApp.frpReport.admin.confirmCancel';
        vm.templateInputType = constants.FRP_MDE_TEMPLATE;
        vm.isView = isView ? true : frpReport ? frpReport.statusFinal === constants.STATUS_FINAL_FRP_MDE : false;
        vm.isWeekly = false;
        vm.isMidSession = false;
        vm.isOther = false;
        vm.requiredFields = [
            'subjective',
            'psychologicalNarrative'
        ];
        vm.midsessionsRemovedList = []; // all for objective and subjective

        vm.checkInValidationSubjectiveTesting = checkInValidationSubjectiveTesting;
        vm.checkInValidationObjectiveTesting = checkInValidationObjectiveTesting;
        vm.dataHeaderReport = {};
        vm.subjectiveImportMdeScores = false;
        vm.objectiveImportMdeScores = false;
        vm.midsessionFields = ["id", "dated",	"testScore", "testScoreLeft", "testScoreRight", "orderNumber", "active", "frpReportPsychSubjectiveResponseId", "frpReportPsychObjectiveResponseId"];
        vm.severity = angular.copy(constants.FRP_PSYCH_SEVERITY);
        vm.severitySubjectives = vm.severity ? vm.severity.subjectives : {};
        vm.severityObjectives = vm.severity ? vm.severity.objectives : {};

        init();

        function init() {
            vm.isMidSession = vm.frpReport && vm.frpReport.frpReportAdmin && vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT;
            vm.reportType = vm.frpReport && vm.frpReport.frpReportAdmin ? vm.frpReport.frpReportAdmin.reportType : null;
            setMidsessionObjectiveResponses();
            setMidsessionSubjectiveResponses();
            setIsFinal();
            setIsDisabled();
            setDisableFields();

            vm.frpReportId = $stateParams.id;
            vm.dataHeaderReport = buildDataHeaderReport(vm.frpReport.patientFRPMDE);

            if (vm.frpReport.firstReport && vm.frpReport.mdeReportId > 0) {
                MdeReportService.getMdePsychReport(vm.frpReport.mdeReportId).then(function(data) {
                    vm.mdePsychReport = data;
                });
            }
        }

        $rootScope.$on('$viewContentLoaded', function() {
            $timeout(function () { // used $timeout to $digest directly
                initCloneFRP();
                vm.isLoadingData = true;
            }, 1000);
        });

        function initCloneFRP() {
            // clone FRPReport after add midSession
            vm.frpReportOld = DataUtils.deepCopy(vm.frpReport);
            if (vm.frpReportOld.frpReportPsych && vm.frpReportOld.frpReportPsych.objectiveResponses) {
                var item = vm.frpReportOld.frpReportPsych.objectiveResponses[0];
                if (item.midsessions) {
                    _.forEach(item.midsessions, function (o) {
                        if (o.dated) {
                            o.dated = new Date(o.dated);
                        }
                    });
                }
            }
            if (vm.frpReportOld.frpReportPsych && vm.frpReportOld.frpReportPsych.subjectiveResponses) {
                var item = vm.frpReportOld.frpReportPsych.subjectiveResponses[0];
                if (item.midsessions) {
                    _.forEach(item.midsessions, function (o) {
                        if (o.dated) {
                            o.dated = new Date(o.dated);
                        }
                    });
                }
            }
            var subjectiveTemplateReportOld = vm.frpReportOld.frpReportPsych.subjectiveTemplate;
            var subjectiveTemplateReport = vm.frpReport.frpReportPsych.subjectiveTemplate;
            if (subjectiveTemplateReportOld) {
                subjectiveTemplateReportOld.value = subjectiveTemplateReport.id.toString();
                if (!_.find(vm.templates, function (t) { return t.id === subjectiveTemplateReport.id && t.inputType === subjectiveTemplateReport.inputType; })) {
                    subjectiveTemplateReportOld.disabled = true;
                }
            }

            var psychologicalNarrativeTemplateReportOld = vm.frpReportOld.frpReportPsych.psychologicalNarrativeTemplate;
            var psychologicalNarrativeTemplateReport = vm.frpReport.frpReportPsych.psychologicalNarrativeTemplate;
            if (psychologicalNarrativeTemplateReportOld) {
                psychologicalNarrativeTemplateReportOld.value = psychologicalNarrativeTemplateReport.id.toString();
                if (!_.find(vm.templates, function (t) { return t.id === psychologicalNarrativeTemplateReport.id && t.inputType === psychologicalNarrativeTemplateReport.inputType; })) {
                    psychologicalNarrativeTemplateReportOld.disabled = true;
                }
            }
        }

        function setMidsessionSubjectiveResponses() {
            if (!vm.reportType) {
                return;
            }
            if (vm.frpReport.frpReportPsych && vm.frpReport.frpReportPsych.subjectiveResponses) {
                vm.frpReport.frpReportPsych.subjectiveResponses.forEach(function (item) {
                    convertDateMidsessions(item.midsessions);
                    item.midsessions = item.midsessions ? item.midsessions : [];
                    var orderNumber = 0;
                    if (item && item.midsessions.length === 0 && !vm.frpReport.frpReportPsych.isMidSessionSubjectiveUpdated) {
                        // IPM FRP_MDE Feedback-20220727 #37, fix clone object frpReport is error
                        var dateMid = !vm.isMidSession ? null : new Date();
                        var midsession = addNewMidsessionSubjective(orderNumber, item.id, dateMid);
                        item.midsessions.push(midsession);
                    }
                    updateOrderNumberMidsession(item.midsessions);
                });
            }
        }

        function addNewMidsessionSubjective(orderNumber, subjectiveResponseId, dateMid) {
            return {id: null,
                frpReportPsychSubjectiveResponseId: subjectiveResponseId,
                frpReportPsychObjectiveResponseId: null,
                dated: dateMid,
                testScore: "",
                testScoreRight: "",
                testScoreLeft: "",
                testScoreSeverity: null,
                testScoreSeverityRight: null,
                testScoreSeverityLeft: null,
                orderNumber: orderNumber,
                active: true,
                $isOpenCalendar: false};
        }

        function setMidsessionObjectiveResponses() {
            if (!vm.reportType) {
                return;
            }
            if (vm.frpReport.frpReportPsych && vm.frpReport.frpReportPsych.objectiveResponses) {
                vm.frpReport.frpReportPsych.objectiveResponses.forEach(function (item) {
                    convertDateMidsessions(item.midsessions);
                    item.midsessions = item.midsessions ? item.midsessions : [];
                    var orderNumber = 0;
                    if (item && item.midsessions.length === 0 && !vm.frpReport.frpReportPsych.isMidSessionObjectiveUpdated) {
                        // IPM FRP_MDE Feedback-20220727 #37, fix clone object frpReport is error
                        var dateMid = !vm.isMidSession ? null : new Date();
                        var midsession = addNewMidsessionObjective(orderNumber, item.id, dateMid);
                        item.midsessions.push(midsession);
                    }
                    updateOrderNumberMidsession(item.midsessions);
                });
            }
        }

        function updateOrderNumberMidsession(midsessions) {
            if (midsessions) {
                midsessions.forEach(function (ms, index) {
                    ms.orderNumber = index;
                });
            }
        }

        function convertDateMidsessions(midsessions) {
            if (midsessions) {
                _.forEach(midsessions, function (o) {
                    o.dated = DateUtils.convertDateIgnoreTimeZone(o.dated);
                    updateDateMidSession(o); // not convertDateIgnoreTimeZone
                });
            }
        }

        /**
         * updateDateMidSession.
         * fix bug IPMproject-B1918: update date null = now
         * @param midsession
         */
        function updateDateMidSession(midsession) {
            if (vm.reportType === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT) {
                midsession.dated = midsession.dated ? midsession.dated : new Date();
            }
        }

        function addNewMidsessionObjective(orderNumber, objectiveResponseId, dateMid) {
            return {id: null,
                frpReportPsychObjectiveResponseId: objectiveResponseId,
                frpReportPsychSubjectiveResponseId: null,
                dated: dateMid,
                testScore: "",
                testScoreRight: "",
                testScoreLeft: "",
                testScoreSeverity: null,
                testScoreSeverityRight: null,
                testScoreSeverityLeft: null,
                orderNumber: orderNumber,
                active: true,
                $isOpenCalendar: false};
        }

        function setDisableFields() {
            if (vm.isView) {
                setDisableAllField();
            } else if (vm.frpReport.frpReportAdmin && vm.frpReport.frpReportAdmin.reportType) {
                if (vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.DISCHARGE_REPORT) {
                    setEnableAllField();
                } else if (vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.MID_SESSION_REPORT) {
                    setEnableAllField();
                } else if (vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.FIRST_REPORT) {
                    setEnableAllField();
                } else if (vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.OTHER_REPORT) {
                    vm.isOther = true;
                    setEnableAllField();
                } else if (vm.frpReport.frpReportAdmin.reportType === constants.FRP_REPORT_TYPE.WEEKLY_REPORT) {
                    vm.isWeekly = true;
                    setEnableAllField();
                }
            } else { // Disable field with ReportType is Empty
                setDisableReportTypeEmpty();
            }
        }

        function setDisableReportTypeEmpty() {
            for (var field in vm.disableFields) {
                if (field === 'testingMidsession'
                    || field === 'testingMidsessionLeft'
                    || field === 'testingMidsessionRight'
                    || field === 'psychTestingSelect'
                    || field === 'testingNotes'
                    || field === 'testingDischarge'
                    || field === 'testingInitialScore'
                    || field === 'testingColMidsession'
                    || field === 'testingInitialScoreLeft'
                    || field === 'testingInitialScoreRight'
                    || field === 'testingDischargeLeft'
                    || field === 'testingDischargeRight'
                    || field === 'testingColMidsessionLeft'
                    || field === 'testingColMidsessionRight' ) {
                    vm.disableFields[field] = true;
                } else {
                    vm.disableFields[field] = false;
                }
            }
        }

        function setEnableAllField() {
            for (var field in vm.disableFields) {
                vm.disableFields[field] = false;
            }
        }

        function setDisableAllField() {
            for (var field in vm.disableFields) {
                vm.disableFields[field] = true;
            }
        }

        function setIsFinal() {
            vm.isFinalize = vm.frpReport && vm.frpReport.frpReportPsych
            && (vm.frpReport.frpReportPsych.status === 'COMPLETE') ? true : false;
        }

        function setIsDisabled() {
            if (vm.isView || (vm.frpReport.patientFRPMDE && (vm.frpReport.patientFRPMDE.status === 'MDE_ABANDONED' || vm.frpReport.patientFRPMDE.isViewFrpProgram))) {
                vm.isDisabled = true;
            } else {
                vm.isDisabled = false;
            }
        }

        function isChanged() {
            if (!vm.frpReportOld || !vm.frpReport) { // isLoading
                return false;
            }
            var fieldsChild = ['objectiveResponses', 'subjectiveResponses'];
            for (var field in vm.frpReport.frpReportPsych) {
                if (!vm.frpReport.frpReportPsych[field]) {
                    vm.frpReport.frpReportPsych[field] = null;
                }
            }
            for (var fieldOld in vm.frpReportOld.frpReportPsych) {
                if (!vm.frpReportOld.frpReportPsych[fieldOld]) {
                    vm.frpReportOld.frpReportPsych[fieldOld] = null;
                }
                if (vm.frpReport.frpReportAdmin.reportType && fieldsChild.includes(fieldOld)) { // just check if there is a report type and the FRP report is opened.
                    updateChildScoreNoMatchPsychResponse(vm.frpReportOld.frpReportPsych[fieldOld], vm.frpReport.frpReportPsych[fieldOld]);
                }
            }
            resolveSubjectiveResponses(vm.frpReportOld.frpReportPsych.subjectiveResponses, vm.frpReport.frpReportPsych.subjectiveResponses);
            return !angular.equals(vm.frpReport, vm.frpReportOld);
        }

        function updateChildScoreNoMatchPsychResponse(frpReportPsychChild, frpReportPsychChildNew) {
            if (!_.isEmpty(frpReportPsychChild)) {
                // add child scoreNoMatch for objectiveResponses or subjectiveResponses
                frpReportPsychChild.forEach(function (child, index) {
                    setChildScoreNoMatch(child, frpReportPsychChildNew[index]);
                    // updateTestScoreSeverityResponse(child);
                    if (!_.isEmpty(child.midsessions)) { // check and update Midsession
                        child.midsessions.forEach(function (childMid) {
                            setChildScoreNoMatch(childMid);
                            // updateTestScoreSeverityMid(childMid);
                        });
                    }
                });
            }
        }

        function updateTestScoreSeverityResponse(fieldResponse) {
            if (fieldResponse) {
                // Properties to check and update if they are empty strings
                var propertiesToCheck = [
                    'initialScoreSeverity',
                    'initialScoreSeverityRight',
                    'initialScoreSeverityLeft',
                    'dischargeSeverity',
                    'dischargeSeverityRight',
                    'dischargeSeverityLeft'
                ];
                // Loop through the properties and set them to null if they are empty strings
                propertiesToCheck.forEach(function (property)  {
                    if (fieldResponse[property] === "") {
                        fieldResponse[property] = null;
                    }
                });
            }
        }

        function updateTestScoreSeverityMid(fieldMid) {
            if (fieldMid) {
                // Properties to check and update if they are empty strings
                var propertiesToCheck = [
                    'testScoreSeverity',
                    'testScoreSeverityRight',
                    'testScoreSeverityLeft'
                ];

                // Loop through the properties and set them to null if they are empty strings
                propertiesToCheck.forEach(function (property) {
                    if (fieldMid[property] === "") {
                        fieldMid[property] = null;
                    }
                });
            }
        }

        function setChildScoreNoMatch(fieldElement, fieldElementNew) {
            if (fieldElementNew && fieldElementNew.scoreNoMatch) {
                fieldElement.scoreNoMatch = fieldElementNew.scoreNoMatch;
            }
        }

        function resolveSubjectiveResponses(subjectiveResponsesOld, subjectiveResponses) {
            if (!subjectiveResponsesOld && !subjectiveResponses) {
                return;
            }
            for (var index = 0; index < subjectiveResponsesOld.length; index++) {
                resolveMisessions(subjectiveResponsesOld[index].midsessions, subjectiveResponses[index].midsessions);
            }
        }

        function resolveMisessions(midsessionsOld, midsessions) {
            if (midsessionsOld && midsessions && midsessionsOld.length !== midsessions.length) {
                return;
            }
            for (var index = 0; index < midsessionsOld.length; index++) {
                resolveMisession(midsessionsOld[index], midsessions[index]);
            }
        }
        function resolveMisession(oldMidsession, midsession) {
            for (var field in vm.midsessionFields) {
                if (!oldMidsession[vm.midsessionFields[field]]) {
                    oldMidsession[vm.midsessionFields[field]] = null;
                }
                if (!midsession[vm.midsessionFields[field]]) {
                    midsession[vm.midsessionFields[field]] = null;
                }
            }

        }
        function checkValidation() {
            var requiredFields = vm.requiredFields.slice();
            if (!vm.isOther) {
                var inputRowMid = "rowMid_{code}_{idx}"; // rowMid_CES_D_0
                var inputRowMidLeft = "rowMidLeft_{code}_{idx}"; // rowMidLeft_CES_D_0
                var inputRowMidRight = "rowMidRight_{code}_{idx}"; // rowMidRight_CES_D_0
                if (vm.frpReport.frpReportPsych.objectiveResponses) {
                    vm.frpReport.frpReportPsych.objectiveResponses.forEach(function (item) {
                        if (item.checked) {
                            requiredFields.push("initialScore_" + item.instrument);
                            requiredFields.push("discharge_" + item.instrument);
                            if (item.midsessions) {
                                item.midsessions.forEach(function (itemMis, index) {
                                    var idRowMid = inputRowMid.replace('{code}', item.instrument).replace('{idx}', index);
                                    requiredFields.push(idRowMid);
                                });
                            }
                        }
                    });
                    // get first row midsessions objective
                    var midsessionsColFirst = vm.frpReport.frpReportPsych.objectiveResponses[0].midsessions;
                    if (midsessionsColFirst) {
                        midsessionsColFirst.forEach(function (item, $index) {
                            // $setDirty colMisDate_objective_{{index}}
                            var colName = 'colMisDate_objective_' + $index;
                            requiredFields.push(colName);
                        });
                    }
                }

                if (vm.frpReport.frpReportPsych.subjectiveResponses) {
                    vm.frpReport.frpReportPsych.subjectiveResponses.forEach(function (item) {
                        if (item.checked) {
                            requiredFields.push("initialScore_" + item.instrument);
                            requiredFields.push("initialScoreLeft_" + item.instrument);
                            requiredFields.push("initialScoreRight_" + item.instrument);
                            requiredFields.push("discharge_" + item.instrument);
                            requiredFields.push("dischargeLeft_" + item.instrument);
                            requiredFields.push("dischargeRight_" + item.instrument);
                            if (item.midsessions) {
                                item.midsessions.forEach(function (itemMis, index) {
                                    var idRowMid = inputRowMid.replace('{code}', item.instrument).replace('{idx}', index);
                                    requiredFields.push(idRowMid);
                                    var idRowMidLeft = inputRowMidLeft.replace('{code}', item.instrument).replace('{idx}', index);
                                    requiredFields.push(idRowMidLeft);
                                    var idRowMidRight = inputRowMidRight.replace('{code}', item.instrument).replace('{idx}', index);
                                    requiredFields.push(idRowMidRight);
                                });
                            }
                        }
                    });
                    // get first row midsessions subjectives
                    var midsessionsColFirst = vm.frpReport.frpReportPsych.subjectiveResponses[0].midsessions;
                    if (midsessionsColFirst) {
                        midsessionsColFirst.forEach(function (item, $index) {
                            // $setDirty colMisDate_subjective_{{index}}
                            var colName = 'colMisDate_subjective_' + $index;
                            requiredFields.push(colName);
                        });
                    }
                }

            }

            requiredFields.forEach(function (field) {
                if (field && vm.editForm[field]) {
                    vm.editForm[field].$setDirty();
                }
            });
        }

        function updateMidssessionsRemoved() {
            vm.frpReport.frpReportPsych.midsessionsRemoved = vm.midsessionsRemovedList;
        }

        vm.save = function () {
            submitData(false);
        }

        vm.finalize = function () {
            submitData(true);
        }

        vm.cancel = function () {
            var isReportChanged = isChanged();
            if (isReportChanged) {
                vm.confirmDialog(vm.cancelMessage);
            } else {
                back();
            }
        }

        function buildDataHeaderReport(patientFRPMDE) {
            if (patientFRPMDE) {
                return {
                    patientName: patientFRPMDE.patient.firstName + " " + patientFRPMDE.patient.lastName,
                    chartNumber: patientFRPMDE.patient.patientId,
                    authorizedProviderName: patientFRPMDE.authorizedProviderName,
                    frpProgramName: patientFRPMDE.frpProgramName,
                    caseNumber: patientFRPMDE.caseNumber,
                    dateOfBirth: patientFRPMDE.patient.dateOfBirth
                };
            }
        };

        function back() {
            goToFrpReport();
        }

        function goToFrpReport() {
            $state.go('frp-report', {id: vm.frpReportId});
        }

        function submitData(finalize) {
            if (vm.isView) {
                return;
            }
            var isInvalidMaxLength = DataUtils.checkForMaxLengthErrors(vm.editForm);
            if (isInvalidMaxLength) {
                return;
            }
            if (finalize) {
                checkValidation();
                // var hasSubjectiveResponses = vm.hasTestingChecked(vm.frpReport.frpReportPsych.subjectiveResponses);
                // var hasObjectiveResponses = vm.hasTestingChecked(vm.frpReport.frpReportPsych.objectiveResponses);
                // if (vm.editForm.$invalid || !hasSubjectiveResponses || !hasObjectiveResponses) {
                if (!vm.reportType || vm.editForm.$invalid) {
                    return;
                }
            }

            if(vm.frpReport.frpReportPsych.psychologicalNarrativeTemplate && vm.frpReport.frpReportPsych.psychologicalNarrativeTemplate.content != vm.frpReport.frpReportPsych.psychologicalNarrative) {
                vm.frpReport.frpReportPsych.psychologicalNarrativeTemplate = null;
            }

            if(vm.frpReport.frpReportPsych.subjectiveTemplate && vm.frpReport.frpReportPsych.subjectiveTemplate.content != vm.frpReport.frpReportPsych.subjective) {
                vm.frpReport.frpReportPsych.subjectiveTemplate = null;
            }
            if (vm.frpReport && vm.frpReport.frpReportPsych) {
                var isValidMidSession = checkValidMidSession(vm.frpReport.frpReportPsych);
                if (!isValidMidSession) {
                    openErrorDialog();
                    return;
                }
                vm.isSaving = true;
                vm.frpReport.frpReportPsych.status = finalize ? 'COMPLETE' : 'INPROGRESS';

                if (vm.reportType) { // fix bug IPMproject-B1917: only update when have reportType
                    vm.frpReport.frpReportPsych.initializedDataTesting = true; // always update initializedDataTesting is true when save frpReportPsych
                    // prepair for Subjectives Testing
                    prepareMidssessionDated(vm.frpReport.frpReportPsych.subjectiveResponses);
                    // prepair for Objectives Testing
                    prepareMidssessionDated(vm.frpReport.frpReportPsych.objectiveResponses);
                    updateMidssessionsRemoved();
                    _.forEach(vm.frpReport.frpReportPsych.midsessionsRemoved, function (item) {
                        item.dated = $filter('date')(item.dated, DateUtils.dateformat());
                    });
                } else {
                    // clear midsessions when report type is not selected
                    if (vm.frpReport.frpReportPsych.subjectiveResponses) {
                        vm.frpReport.frpReportPsych.subjectiveResponses.forEach(function (sr) {
                            sr.midsessions = [];
                        });
                    }
                    if (vm.frpReport.frpReportPsych.objectiveResponses) {
                        vm.frpReport.frpReportPsych.objectiveResponses.forEach(function (sr) {
                            sr.midsessions = [];
                        });
                    }
                }
                FrpReportService.saveFRPReportPsych(vm.frpReport).then(function (response) {
                    onSaveSuccess(response)
                }, function error(e) {
                    onSaveError(e);
                });
            }
        }

        function checkValidMidSession(frpReportPsych) {
            var isValid = false;
            if (frpReportPsych.subjectiveResponses) {
                var lengthMidSessionSub = _.size(frpReportPsych.subjectiveResponses[0].midsessions); // size firstItem subjective
                isValid = _.isEmpty(_.filter(frpReportPsych.subjectiveResponses, function (item) {
                    return lengthMidSessionSub !== _.size(item.midsessions);
                }));
            }
            if (isValid && frpReportPsych.objectiveResponses) {
                var lengthMidSessionObj = _.size(frpReportPsych.objectiveResponses[0].midsessions); // size firstItem objective
                isValid = _.isEmpty(_.filter(frpReportPsych.objectiveResponses, function (item) {
                    return lengthMidSessionObj !== _.size(item.midsessions);
                }));
            }
            return isValid;
        }

        function prepareMidssessionDated(items) {
            var firstItem = items[0];
            if (!_.isEmpty(firstItem.midsessions)) {
                _.forEach(items, function (item) {
                    formatDateToServer(item.midsessions, firstItem);
                });
            }
        }

        function formatDateToServer(midsessions, item) {
            _.forEach(midsessions, function (ms, $index) {
                if (item.midsessions[$index])
                ms.dated = DateUtils.convertLocalDateToServer(item.midsessions[$index].dated);
            });
        }

        function onSaveSuccess(response) {
            vm.isSaving = false;
            goToFrpReport();
        }

        function onSaveError(error) {

            var errorMessage = error.headers('X-ipmApp-error-message');
            if (error && (error.status === 409 || error.status === 400)) {
                vm.error = 'ERROR';
                openErrorDuplicateDialog();
            }
            vm.isSaving = false;
        }

        function checkInValidationSubjectiveTesting() {
            if (vm.frpReport.frpReportPsych && vm.frpReport.frpReportPsych.subjectiveResponses) {
                return _.isEmpty(_.filter(vm.frpReport.frpReportPsych.subjectiveResponses, function (item) {
                    return item && item.checked;
                }));
            }
            return false;
        }

        // vm.hasTestingChecked = function(listItem) {
        //     if (vm.isOther) return true;
        //     // At least 1 Testing is required
        //     if (listItem) {
        //         return !_.isEmpty(_.filter(listItem, function (s) { return s.checked; }))
        //     }
        //     return false;
        // }

        function checkInValidationObjectiveTesting() {
            if (vm.frpReport.frpReportPsych && vm.frpReport.frpReportPsych.objectiveResponses) {
                return _.isEmpty(_.filter(vm.frpReport.frpReportPsych.objectiveResponses, function (item) {
                    return item && item.checked;
                }));
            }
            return false;
        }

        vm.confirmDialog = function (message) {
            $uibModal.open({
                templateUrl: 'app/frp-mde/confirm-dialog/confirm-dialog.html',
                controller: 'ConfirmController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center warning-dialog',
                resolve: {
                    message: function () {
                        return {'content': message};
                    }
                }
            }).result.then(function (result) {
                if (result) {
                    back();
                }
            });
        }

        vm.importMdeScores = function(type) {
            if (type === 'subjective') {
                if (vm.subjectiveImportMdeScores && vm.mdePsychReport.subjectives) {
                    _.forEach(vm.frpReport.frpReportPsych.subjectiveResponses, function (frpSub) {
                        var mdeSub = _.first(_.filter(vm.mdePsychReport.subjectives, function (item) {
                            var code = item.code.replace("-", "");
                            var instrument = frpSub.instrument.replace("_", "");
                            return item && frpSub && code === instrument;
                        }));
                        if (mdeSub) {
                            frpSub.initialScore = mdeSub.score;
                            frpSub.initialScoreLeft = mdeSub.scoreLeft;
                            frpSub.initialScoreRight = mdeSub.scoreRight;
                            frpSub.checked = mdeSub.checked; // clone checked
                        }
                    });
                }
            } else if (type === 'objective') {
                if (vm.objectiveImportMdeScores && vm.mdePsychReport.objectives) {
                    _.forEach(vm.frpReport.frpReportPsych.objectiveResponses, function (frpSub) {
                        var mdeSub = _.first(_.filter(vm.mdePsychReport.objectives, function (item) {
                            var code = item.code.replace("-", "");
                            var instrument = frpSub.instrument.replace("_", "");
                            return item && frpSub && code === instrument;
                        }));
                        if (mdeSub) {
                            frpSub.initialScore = mdeSub.score;
                            frpSub.checked = mdeSub.checked; // clone checked
                        }
                    });
                }
            }
        }

        function openErrorDuplicateDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.frpReport.error.saveReportDuplicateMessage"},
                    title: function () {return "ipmApp.frpReport.error.saveReportDuplicateTitle"}
                }
            }).result.then(function() {
                $window.location.reload(); // reload page to load new data FRP
            }, function() {
            });
        }

        function openErrorDialog() {
            $uibModal.open({
                templateUrl: 'app/shared/error-dialog/error-dialog.controller.html',
                controller: 'ErrorDialogController',
                controllerAs: 'vm',
                size: 'sm',
                windowClass: 'absolute-center',
                backdrop: 'static',
                resolve: {
                    isTranslate: function () {return true},
                    message: function () {return "ipmApp.frpReport.error.saveReportDuplicateMessage"},
                    title: function () {return "ipmApp.frpReport.error.saveReportDuplicateTitle"}
                }
            }).result.then(function() {

            }, function() {
            });
        }
    }
})();
