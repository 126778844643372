(function() {
    'use strict';

    SelectScoreFrpMdeController.$inject = ['$scope'];

    function SelectScoreFrpMdeController($scope) {
        var vm = this; //jshint ignore:line
        vm.enableSearchTextMatch = false;
        vm.selectScoreOnChange = selectScoreOnChange;

        init();

        function init() {
            vm.numberMaxlength = vm.numberMaxlength ? vm.numberMaxlength : 1000;
            vm.isHideSeverity = vm.isHideSeverity ? true : false;
            vm.selectId = vm.selectId ? vm.selectId : new Date().getTime().toString();
            vm.isDisabled =  vm.isDisabled ? vm.isDisabled : false;
            vm.isRequired =  vm.isRequired ? vm.isRequired : false;
            vm.severityModel = vm.severityModel ? vm.severityModel : null; // it's classification
            vm.scoreNoMatch = false; // init scoreNoMatch is false
            if (vm.isInitData) {
                updateInitSeverityModel();
            }
        }

        function selectScoreOnChange() {
            var selectScores = getDataSelectScore();
            updateSeverity(selectScores);
            updateScoreNoMatch(selectScores);
            emitSeverity();
        }

        function updateScoreNoMatch(selectScores) {
            vm.scoreNoMatch = selectScores && vm.severityModel ? false : true;
        }

        function updateSeverity(selectScores) {
            vm.severityModel = !_.isEmpty(selectScores) ? _.first(selectScores) : '';
        }

        function updateInitSeverityModel() {
            // Update the severityModel if it is initially empty and vm.scoreModel is a valid number.
            // This helps ensure the severityModel reflects the scoreModel.
            if (vm.scoreModel && !vm.severityModel && !_.isNaN(vm.scoreModel)) {
                selectScoreOnChange();
            }
        }

        function emitSeverity() {
            vm.onChange({severity: vm.severityModel});
        }

        function getDataSelectScore() {
            var textScoreModel = angular.copy(vm.scoreModel);
            var initialScore = angular.copy(vm.selectData);
            var textSearch = textScoreModel ? textScoreModel.trim().toLowerCase() : '';

            if (textSearch) {
                var initialScoreResults = _.filter(initialScore, function (item) {

                    var isNumberMatch = !_.isNaN(textSearch) &&
                        checkMinRange(item, textSearch) &&
                        (Number(textSearch) <= item.maxRange);
                    if (vm.enableSearchTextMatch) {
                        var isTextMatch = item.value.toLowerCase().indexOf(textSearch) !== -1;
                        return isTextMatch || isNumberMatch;
                    }
                    return isNumberMatch;
                });
                return formatScoreResult(textScoreModel, initialScoreResults);
            }
            return [];
        };

        function checkMinRange(severityItem, textScore) {
            if (severityItem) {
                if (severityItem.key === 'SEVERE_ANXIETY' || severityItem.key === 'SEVERE_DEPRESSION') { // HAM_A && HAM_D
                    return severityItem.minRange < Number(textScore);
                }
                return severityItem.minRange <= Number(textScore)
            }
            return false;
        }

        function formatScoreResult(textSearch, initialScoreResults) {
            return  _.map(initialScoreResults, function (item) {
                // If textSearch is null, display will using 'value' else use 'name'
                return textSearch ? item.name : item.value;
            });
        }

        $scope.$watch('vm.updateSeverityData', function (oldVal, newVal) {
            if (oldVal !== newVal) {
                selectScoreOnChange();
            }
        });

    }

    angular.module('ipmApp').component('selectScoreFrpMde', {
        bindings: {
            numberMaxlength: '<',
            placeholderLabel: '<',
            isDisabled: '<',
            isRequired: '<',
            selectId: '<',
            selectData: '<',
            isHideSeverity: '<',
            isInitData: '<',
            updateSeverityData: '<',
            scoreNoMatch: '=',
            scoreModel: '=',
            severityModel: '=',
            onChange: '&'
        },
        templateUrl: 'app/frp-mde/select-score-frp-mde-component/select-score-frp-mde.component.html',
        controller: SelectScoreFrpMdeController,
        controllerAs: 'vm'
    })
})();
