(function () {
    'use strict';

    angular
        .module('ipmApp')
        .directive('checkMaxlength', checkMaxlength);

    function checkMaxlength() {
        return {
            restrict: 'A', // This directive will be used as an attribute
            require: 'ngModel', // We need access to ngModelController
            link: function (scope, element, attrs, ngModelCtrl) {

                var maxLength = parseInt(attrs.checkMaxlength, 10);  // Get the maxlength value from the attribute

                // Custom validator for maxlength
                function validateMaxlength(value) {
                    if (value && value.length > 0) {
                        ngModelCtrl.$setDirty(); // fix not showing messages maxlength when invalid
                    }
                    if (maxLength && maxLength > 0) { // only check if maxlength is greater than zero
                        if (value && value.length > maxLength) {
                            ngModelCtrl.$setValidity('maxlength', false);  // Set validity to false if length exceeds maxlength
                        } else {
                            ngModelCtrl.$setValidity('maxlength', true);  // Set validity to true if length is within limit
                        }
                    }
                    return value; // Return the value to the model
                }

                // used to watch changes in the input value and reapply the validation on every user input or format update.
                ngModelCtrl.$parsers.push(validateMaxlength);
                ngModelCtrl.$formatters.push(validateMaxlength);
            }
        };
    }
})();
